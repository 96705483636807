<template>
    <section class="not-found-wrapper">
      <div class="container">
        <div class="not-found-sec">
          <div class="found-img">
            <img src="@/assets/images/not-found.png" class="mt-4" alt="error">
          </div>
          <div class="nodata-found-msg">
            <h3>{{ $t('ooops', 'Ooops') }}!</h3>
          </div>
          <div class="found-heading">
            <router-link to="/" class="btn_submit_cl"><img src="@/assets/images/arrow_back_btn.svg"> Back to Home</router-link>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.not-found-wrapper {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
    height: 100vh;
    background-color: #000;
}
.not-found-sec {
    text-align: center;
}
.found-img img {
    max-width: 300px;
    border-radius: 10px;
}
.nodata-found-msg h3 {
    color: #f36c21;
    font-size: 6rem;
    font-weight: 500;
    line-height: 6rem;
    margin-bottom: 40px;
    letter-spacing: -0.015625em;
}
.found-img {
    margin-bottom: 10px;
}
.nodata-found-msg p {
    color: #fff;
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 45px;
}
.found-heading a.btn_submit_cl img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(78%) saturate(0%) hue-rotate(32deg) brightness(102%) contrast(105%);
}
.found-heading {
    text-align: center;
    display: inline-block;
}
.found-heading a.btn_submit_cl {
    background-color: #f36c21;
    border-color: #f36c21;
    display: flex;
    color: #fff;
    height: 44px;
    min-width: 78px;
    padding: 0 19.5555555556px;
    padding-right: 32px;
    padding-left: 32px;
    font-size: .875rem;
    border-radius: 28px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, .2), 0px 2px 2px 0px rgba(0, 0, 0, .14), 0px 1px 5px 0px rgba(0, 0, 0, .12);
    text-transform: uppercase;
    letter-spacing: .0892857143em;
    align-items: center;
    column-gap: 5px;
    justify-content: center;
    font-family: "Roboto", sans-serif;
}
.light .not-found-wrapper {
    background-color: #fff;
}
.light .nodata-found-msg p{
    color: #000;
}
</style>
