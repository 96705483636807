const ProfileModule = () => import(/* webpackChunkName: "authorization-module" */ './views/Module.vue'); 
const ResetPassword = () => import(/* webpackChunkName: "forget-password" */ './views/ResetPassword.vue');
const StakeSettings = () => import(/* webpackChunkName: "forget-password" */ './views/StakeSettings.vue');

const ProfileRoutes =   {
    path: '/',
    component: ProfileModule,
    children: [
      {
        path: 'reset-password',
        name: 'reset-password',
        component: ResetPassword
      },
      {
        path: 'stake-settings',
        name: 'stake-settings',
        component: StakeSettings
      }
    ],
  }

export default ProfileRoutes;