
export default function handlerErrorFromWalletApis(error) {
    if (error) {
        if (error?.response?.status == 422) {
            if (error.response.data.details)
                return Promise.reject(error.response.data.details[Object.keys(error.response.data.details)[0]]);
            else
                return Promise.reject([error.response.data.errors]);
        }
        else if (error?.response?.status == 401) {
            clearDataOnUnauthorised()
        }
        else if (error?.response?.status == 400) {
            return Promise.reject([error?.response.data.errors]);
        }
        else {
            return Promise.reject(error?.response);
        }
    }
}

function clearDataOnUnauthorised() {
    window.cookies.keys().forEach(cookie => window.cookies.remove(cookie))
    window.store.dispatch('resetAllState');
    window.router.push({name:'Home'});
}