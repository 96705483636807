
import AuthorizationRoutes from "@/modules/authorization";
import BetsRoutes from "@/modules/bets";
import BonusRoutes from "@/modules/bonus";
import CricketFightRoutes from "@/modules/cricket-fight";
import FavouritesRoutes from "@/modules/favourites";
import GamesRoutes from "@/modules/games";
import MatkaRoutes from "@/modules/matka";
import NotificationsRoutes from "@/modules/notification";
import ProfileRoutes from "@/modules/profile";
import RaceRoutes from "@/modules/race";
import ReferEarnRoutes from "@/modules/refer-earn";
import SportsRoutes from "@/modules/sports";
import StaticRoutes from "@/modules/static";
import WalletRoutes from "@/modules/wallet";
import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("../views/Home.vue");
const MaintenanceComponent = () => import("@/shared/components/maintenance/Maintainence.vue");
import NotFound from "@/shared/components/no-data/404.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: MaintenanceComponent
  },
  AuthorizationRoutes,
  StaticRoutes,
  ProfileRoutes,
  BetsRoutes,
  FavouritesRoutes,
  NotificationsRoutes,
  ReferEarnRoutes,
  BonusRoutes,
  WalletRoutes,
  GamesRoutes,
  SportsRoutes,
  RaceRoutes,
  MatkaRoutes,
  CricketFightRoutes,
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound', 
    component: NotFound 
  },
];


export default createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to the top of the page on route change
    return { top: 0 };
  },
});

