const BetsModule = () => import(/* webpackChunkName: "bets-module" */ './views/Module.vue'); 
const Bets = () => import(/* webpackChunkName: "bets" */ './views/Bets.vue');
const SettledBets = () => import(/* webpackChunkName: "bets" */ './views/SettledBets.vue');
const AccountStatement = () => import(/* webpackChunkName: "bets" */ './views/AccountStatement.vue');
const ProfitLoss = () => import(/* webpackChunkName: "profit-loss" */ './views/ProfitLoss.vue');
const ProfitLossDetails = () => import(/* webpackChunkName: "transactions" */ './views/ProfitLossDetails.vue');

const BetsRoutes =   {
    path: '/',
    component: BetsModule,
    children: [
      {
        path: 'bets',
        name: 'bets',
        component: Bets
      },
      {
        path: 'settled-bets',
        name: 'settled-bets',
        component: SettledBets
      },
      {
        path: 'account-statements',
        name: 'account-statements',
        component: AccountStatement
      },
      {
        path: 'profit-loss',
        name: 'profit-loss',
        component: ProfitLoss
      },
      {
        path: 'profit-loss-details',
        name: 'profit-loss-details',
        component: ProfitLossDetails 
      },
    ],
  }

export default BetsRoutes;