const GamesModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue'); 
const Games = () => import(/* webpackChunkName: "games-module" */ './views/Games.vue'); 
const ResponsibleGambling = () => import(/* webpackChunkName: "slot-games" */ './components/ResponsibleGambling.vue');
const OnlineCricketBetting = () => import(/* webpackChunkName: "slot-games" */ './components/OnlineCricBetting.vue');
const OnlineBetting = () => import('./components/OnlineBetting.vue');
const IplBetting = () => import('./components/IplBetting.vue');
const SoccerBetting = () => import('./components/SoccerBetting.vue');
const OnlineSportsBetting = () => import('./components/OnlineSportBetting.vue');
const TennisBetting = () => import('./components/TennisBetting.vue');
const MongoBettingApp = () => import('./components/MangoBettingApp.vue');


const GamesRoutes =   {
    path: '/',
    component: GamesModule,
    children: [
      {
        path: 'games/:type?',
        name: 'Games',
        component: Games
      },

      {
        path: 'responsible-gambling',
        name: 'responsible-gambling',
        component: ResponsibleGambling
      },

      {
        path: 'online-cricket-betting',
        name: 'online-cricket-betting',
        component: OnlineCricketBetting
      },

      {
        path: 'online-betting',
        name: 'online-betting',
        component: OnlineBetting
      },

      {
        path: 'ipl-betting',
        name: 'ipl-betting',
        component: IplBetting
      },

      {
        path: 'soccer-betting',
        name: 'soccer-betting',
        component: SoccerBetting
      },

      {
        path: 'online-sports-betting',
        name: 'online-sports-betting',
        component: OnlineSportsBetting
      },

      {
        path: 'tennis-betting',
        name: 'tennis-betting',
        component: TennisBetting
      },

      {
        path: 'MONGO-online-app',
        name: 'MONGO-online-app',
        component: MongoBettingApp
      },

    ],
  }

export default GamesRoutes;