const state = {
    userBonus: null
};
const getters = {
    userBonus: state => state?.userBonus
};
const actions = {
    setUserBonus({ commit }, userBonus) {
        commit('setUserBonus', userBonus);
    }
};
const mutations = {
    setUserBonus(state, userBonus) {
        state.userBonus = userBonus
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};