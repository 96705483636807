const SportsModule = () => import(/* webpackChunkName: "sports-module" */ './views/Module.vue'); 
const SportEventDetail = () => import(/* webpackChunkName: "inplay" */ './views/SportEventDetail.vue');
const Sports = () => import(/* webpackChunkName: "sports" */ './views/Sports.vue');
const SportsBook = () => import(/* webpackChunkName: "sport-book" */ './views/SportsBook.vue');

const SportsRoutes =   {
    path: '/',
    component: SportsModule,
    children: [
      {
        path: 'sports-event-detail/:event_id',
        name: 'sports-event-detail',
        component: SportEventDetail
      },
      {
        path: 'sports/:type/:id',
        name: 'sports',
        component: Sports
      },
      {
        path: 'sports-book',
        name: 'sports-book',
        component: SportsBook
      },
    ],
  }

export default SportsRoutes;