	
<template>
	<div :class="{ globalActive: globalLoader }">
		<template v-if="isSiteStatusCalled">
			<template v-if="checkForHeaderShow() && !underMaintenance">
				<Header />
			</template>
	
			<SportSelection v-if="!SportSelectionExcludedRoutes.includes($route.name) && !underMaintenance && $route.name != 'NotFound'" />
	
			<router-view v-if="$siteSettings.data || underMaintenance" />
	
			<template v-if="!underMaintenance">
				<MobileFooter v-if="!footerExcludedRoutes.includes($route.name)" />
				<Footer v-if="$route.name === 'Home'" />
			</template>
	
			<div data-bs-toggle="modal" data-bs-target="#notification-alert" ref="notifyMe"></div>
			<NotificationAlert @allowNotifications="allowNotifications" @cancelNotifications="cancelNotifications" />
	
			<div v-if="successMsg" :class="{ show: successMsg }" class="successfully-wrapper" style="z-index: 999999999;"
				ref="successmodal" id="successfullyModal">
				<SuccessModal :message="successMsg" />
			</div>
	
			<div v-if="errorMsg" :class="{ show: errorMsg }" class="successfully-wrapper" style="z-index: 999999999;"
				id="wrongtoaster">
				<ErrorModal :message="errorMsg" />
			</div>
		</template>

		<div class="fileList-loader global_loader" v-if="globalLoader || !isSiteStatusCalled">
			<div class="loader">
				<div class="sk-fading-circle">
					<div class="sk-circle1 sk-circle"></div>
					<div class="sk-circle2 sk-circle"></div>
					<div class="sk-circle3 sk-circle"></div>
					<div class="sk-circle4 sk-circle"></div>
					<div class="sk-circle5 sk-circle"></div>
					<div class="sk-circle6 sk-circle"></div>
					<div class="sk-circle7 sk-circle"></div>
					<div class="sk-circle8 sk-circle"></div>
					<div class="sk-circle9 sk-circle"></div>
					<div class="sk-circle10 sk-circle"></div>
					<div class="sk-circle11 sk-circle"></div>
					<div class="sk-circle12 sk-circle"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import { defineAsyncComponent } from 'vue';
import { mapGetters } from 'vuex';
import NotificationAlert from "./shared/components/modal/NotificationAlert.vue";
export default {
	name: 'App',
	components: {
		Header: defineAsyncComponent(() =>
			import('@/shared/components/header/Header.vue')
		),
		SportSelection: defineAsyncComponent(() =>
			import('./shared/components/sport-selection/SportSelection.vue')
		),
		Footer: defineAsyncComponent(() =>
			import('./shared/components/footer/Footer.vue')
		),
		SuccessModal: defineAsyncComponent(() => 
			import('./shared/components/modal/SuccessModal.vue')
		),
		ErrorModal: defineAsyncComponent(() => 
			import('./shared/components/modal/ErrorModal.vue')
		),
		MobileFooter: defineAsyncComponent(() => 
			import('./shared/components/footer/MobileFooter.vue')
		),
		Maintainence: defineAsyncComponent(() =>
			import('./shared/components/maintenance/Maintainence.vue')
		),
		NotificationAlert
	},
	data() {
		return {
			onLine: navigator.onLine,
			showBackOnline: false,
			refreshInit: false,
			scY: 0,
			scTimer: 0,
			footerExcludedRoutes:[
                'login',
                'sign-up',
                'forget-password',
                'CricketFight',
                'MatchDetails',
                'PlaceBet',
                'MyContests',
                'MyContestBetDetails',
				// 'NotFound'
            ],
			SportSelectionExcludedRoutes:[
                'login',
                'sign-up',
                'forget-password',
				'profit-loss-details',
				'CricketFight',
				'MatchDetails',
				'PlaceBet',
				'MyContests',
				'MyContestBetDetails',
				'sports-event-detail'
            ],
			userData: null,
			DownloadAppModal:null,
			langData: null,
			isSiteStatusCalled: false
		}
	},
	computed: {
		...mapGetters(['successMsg', 'errorMsg', 'siteVersion','underMaintenance','eventDetailHideSections','globalLoader','eventDetailHideSections']),
	},
	created() {
		// this.setTheme()
	},
	async mounted() {
		//for use on js pages
		window.store = this.$store;
		window.cookies = this.$cookies;
		window.router = this.$router;
		window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`	
		setHeaders();
		this.$errorToast(null);
		this.$successToast(null);

		this.getSiteVersion();
		if (this.$auth.status) {
			// callFavouriteApis();
			if(!this.$auth.user?.notificationUnsubscribed && !this.$auth.user?.notificationSubscribed){
				this.requestNotificationPermission()
			}
		}

		//checking internet connection
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		window.addEventListener('scroll', this.onScroll);
		if (localStorage.getItem('default_class')) {
			document.documentElement.className = localStorage.getItem('default_class')
		}
		if (this.$auth.status){
			if(this.$route.name=='Home'){
				// this.DownloadAppModal = new Modal(document.getElementById('DownloadAppModal'));
				// this.DownloadAppModal.show();
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
	},
	methods: {
		requestNotificationPermission() {
			if (!('Notification' in window)) {
				console.log('This browser does not support notifications.');
				return;
			}
			if (Notification.permission === 'granted') {				
				this.getSubscriptionData();
			} else if (Notification.permission !== 'denied') {
				this.openNotificationBox()
			}
		},
		openNotificationBox(){
			this.$refs.notifyMe.click()
		},
		allowNotifications(){
			Notification.requestPermission().then(permission => {
				if (permission === 'granted') {
					console.log('Notification permission granted.');
					this.getSubscriptionData();
				}
			});
		},
		cancelNotifications(){
			let user=this.$store.getters.stateUser;
			user.notificationUnsubscribed=1;
			this.$store.dispatch('setUser', user);
		},
		async getSubscriptionData(){
			let user=this.$store.getters.stateUser;
			user.notificationSubscribed=1;
			this.$store.dispatch('setUser', user);
			if ('serviceWorker' in navigator) {
				try {
					await navigator.serviceWorker.register('/service-worker.js').then(async registration => {

						setTimeout(async () => {
							if ('PushManager' in window) {
								if (registration.pushManager) {
									// Subscribe to push notifications
									const subscription = await registration.pushManager.subscribe({
										userVisibleOnly: true,
										applicationServerKey: process.env.VUE_APP_PUBLIC_VAPID_KEY
									});
									this.deviceData=subscription
									this.subscribeNotifications()
								} else {
									console.warn('PushManager is not available');
								}
							} else {
								console.warn('Push notifications are not supported');
							}
						}, 1000)

					}).catch(e => {
						console.log(e);
					});

					
				
				} catch (error) {
					console.error('Service Worker registration failed:', error);
				}
			} else {
				console.warn('Service workers are not supported in this browser.');
			}
		},
		subscribeNotifications(){
			let data = {
					"deviceData": this.deviceData,
					"domainName": window.location.host,
					};
					this.$apiCall('post', 'SUBSCRIBE_NOTIFICATION', data, 'notification').then(response => {
						this.loadingSignUp = false;
					if (response) {
						console.log(response)
					}
				}).catch(error => {
					this.loadingSignUp = false;
					if (error)
						this.$errorToast(error[0]);

				});
		},
		async getSiteVersion() {
			this.$store.dispatch('setGlobalLoader', true);
			this.$apiCall('get', 'GET_SITE_VERSION', null, 'user', { domain: window.location.hostname }).then(async response => {
				this.$store.dispatch('setGlobalLoader', false);
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.$errorToast(response.data.debug[0]);
					} else {
						let resData = response?.data?.data;
						let currentVersion = resData.version;
						let userSystemVersion = this.siteVersion || 0;		
						if (!userSystemVersion || currentVersion != userSystemVersion || !this.$siteSettings.data) {
							this.isSiteStatusCalled = false;
							this.$store.dispatch('setSiteVersion', currentVersion);
							this.getSiteSettingsServiceCall();
						} else {
							this.setSiteSttings(this.$siteSettings.data);
						}
						if (resData.maintenance) {
							const { from_date, to_date } = resData.maintenance;
							const fromDateTime = new Date(from_date).getTime();
							const toDateTime = new Date(to_date).getTime();
							const currentDateTime = Date.now();

							const isInMaintenanceWindow = fromDateTime <= currentDateTime && currentDateTime <= toDateTime;

							if (fromDateTime && toDateTime && isInMaintenanceWindow) {
								this.$store.dispatch('setUnderMaintenance', resData.maintenance);
								this.$router.push({ name: 'Maintenance' });
							}
						}
						else {
							if(this.underMaintenance) {
								this.$router.push({name:'Home'});
							}
							this.$store.dispatch('setUnderMaintenance', null); 
						}
						this.$store.dispatch('setGlobalLoader', false);
					}
				}
			}).catch(error => {
				if (error) {
					this.$store.dispatch('setGlobalLoader', false);
					this.$errorToast(error[0]);
				}
			});
		},
		async getSiteSettingsServiceCall() {
			this.$store.dispatch('setGlobalLoader', true);
			this.$apiCall('get', 'GET_SITE_SETTINGS', null, 'user', { domain: window.location.hostname }).then(async response => {
				this.$store.dispatch('setGlobalLoader', false);
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.$errorToast(response.data.debug[0]);
					} else {
						let resData = response?.data.data;
						let casino;
						if(response?.data.data.casino_url) {
							const getCasinoGame = async () => {
							let gameResp = await axios.get(response?.data.data.casino_url)
							return gameResp.data
							};
							casino = await getCasinoGame();
						} else {
							casino = response?.data.data?.casino
						}	
						this.$store.dispatch('setSettings', resData.site_settings);
						this.$store.dispatch('setGamesData', casino);
						this.setSiteSttings(resData.site_settings);
					}
				}
			}).catch(error => {
				this.$store.dispatch('setGlobalLoader', false);
				if (error) {					
					this.$errorToast(error[0]);
				}
			});
		},

		setSiteSttings(siteData) {
			try {
				if (siteData.language_translate_list) {
					this.$setCurrentLangData(siteData.language_translate_list, siteData.language_code, siteData.aws_url)
					this.$store.dispatch('setLanguagesData', siteData.language_translate_list);
				}
				this.$store.dispatch('setSettings', siteData);
				this.setManifest(siteData);
				// Create FAVICON
				const favElement = document.createElement('link');
				favElement.rel = 'icon';
				favElement.href = siteData.aws_url + siteData.storage_path.domain_image + siteData.fav_logo;
				document.head.insertAdjacentElement('beforeend', favElement);
				
				const title = document.getElementById("title");
				title.text =  siteData.domain_name;
				try {
					if (siteData.front_css != null && siteData.front_css != '') {
						const styleElement = document.createElement('style');
						styleElement.type = 'text/css';
						styleElement.appendChild(document.createTextNode(siteData.front_css));

						document.head.insertAdjacentElement('beforeend', styleElement);

						this.convertCSSToJson(siteData.front_css);
					}
				} catch (error) {
					console.log("ERROR 1: ", error);
				}	
				if (this.$auth.status) {
					this.getWalletBalance();
					this.getFavourites();
				}
				if(siteData.meta_tags) {
					document.head.innerHTML += siteData.meta_tags;
				};
				if(siteData.g_tags) {
					this.setGoogleAnalyticsTag(siteData.g_tags);
				};
				if(siteData.site_map) {
					const sitemapLink = document.createElement("link");
					sitemapLink.rel = "sitemap";
					sitemapLink.type = "application/xml";
					sitemapLink.title = "Sitemap";
					sitemapLink.href = siteData.aws_url + siteData.storage_path.site_map + siteData.site_map;
					document.head.appendChild(sitemapLink);
				};
			} catch (error) {
				this.$store.dispatch('setGlobalLoader', false);
				this.$errorToast(error);
			}
			this.isSiteStatusCalled = true;
		},
		setGoogleAnalyticsTag(gTag) {
			let script1 = document.createElement('script');
			script1.async = true;
			script1.src = `https://www.googletagmanager.com/gtag/js?id=${gTag}`;

			let script2 = document.createElement('script');
			script2.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', '${gTag}');
			`;

			document.head.appendChild(script1);
			document.head.appendChild(script2);
		},
		getFavourites() {
			let data = {
				"game_type": 1
			}
			this.$apiCall('post', 'GET_FAVOURITE_LIST', data).then(response => {
				if (response) {
					if (response.status == 200) {
						if (response?.data?.status != 0) {
							var allData = response.data.data;
							var ids = [];
							for (var i = 0; i < allData.length; i++) {
								ids.push(allData[i].match_id)
							}
							this.$store.dispatch('setFavouriteGames', ids)
						}
					}
				}
			}).catch(error => {
				if (error) {
				}
			});
		},
		setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}
			
			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);
			
			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title			
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);

		},
		getWalletBalance() {
			this.$apiCall('post', 'WALLET_BALANCE').then(response => {
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response.data.bl;
					user.exposure = response.data.ex;
					user.bets_count = response.data.bc;
					user.earnBonus = response.data.eb;
					this.$store.dispatch('setUser', user);
					if ((user.exp - this.$dateTime.currentTimestamp()) < 10) {
						this.refreshAuthToken(headers);
					} else {
						setTimeout(function () {
							this.getWalletBalance();
						}.bind(this), 2000);
					}
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				console.log("wallet balance Error ")
				if (error) {
					console.log(error);
					this.$errorToast(error[0]);
				}
			});
		},
		refreshAuthToken(refresh = false) {
			this.$apiCall('post', 'AUTH_REFRESH').then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				this.refreshInit = false;
				if(refresh) {
					router.go();
				}
			}).catch(error => {
				if (error) {
					this.$errorToast(error[0]);
				}
				this.refreshInit = false;
			});
		},
		checkForHeaderShow() {
			if (this.$route.name == 'search') {
				return false;
			} else if (this.eventDetailHideSections && this.eventDetailHideSections.includes('show_full_screen') && this.$route.name == 'sports-event-detail') {
				return false;
			} else {
				return true;
			}
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},
		goToTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		},
		onScroll() {
			if (this.scTimer) return;
			this.scTimer = setTimeout(() => {
				this.scY = window.scrollY;
				clearTimeout(this.scTimer);
				this.scTimer = 0;
			}, 100);
		},
		setTheme(theme = 'light') {
			if(!localStorage.getItem("default_class")) {
				document.documentElement.className = theme;
            	localStorage.setItem("default_class", theme);
			}
		},
		convertCSSToJson(cssString) {
			const styles = {};
			const customProperties = {};

			// Extract custom properties from :root
			const rootMatch = cssString.match(/:root\s*{([^}]*)}/);
			if (rootMatch) {
				const rootDeclarations = rootMatch[1].split(';').filter(declaration => declaration.trim() !== '' && (declaration.includes('--primary-color') || declaration.includes('--secondary-color')));
				rootDeclarations.forEach(declaration => {
					const [property, value] = declaration.split(':').map(part => part.trim());
					customProperties[property] = value;
				});
			}
			// localStorage.setItem('CustomPropertiesTheme', JSON.stringify(customProperties));
		},
	},
	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		}
	}
}
</script>

