import axios from 'axios';
import { TIME_10000 } from '@/shared/constants/services-const';
import handlerErrorFromApis from './errorHandler';
import handlerErrorFromWalletApis from './walletErrorHandler';

const createApiInstance = (baseURL) => {
    return axios.create({
        baseURL: baseURL,
        timeout: TIME_10000,
    });
};

const userApiInstance = createApiInstance(process.env.VUE_APP_AUTH_API);
const sportsApiInstance = createApiInstance(process.env.VUE_APP_CLICK_API);
const fantasyApiInstance = createApiInstance(process.env.VUE_APP_FANTACY_API);
const walletApiInstance = createApiInstance(process.env.VUE_APP_GET_ID_WALLET);
const bonusApiInstance = createApiInstance(process.env.VUE_APP_BONUS_API);
const notificationApiInstance = createApiInstance(process.env.VUE_APP_NOTIFICATION_API);

const apiInstances = {
    user: userApiInstance,
    sports: sportsApiInstance,
    fantasy: fantasyApiInstance,
    wallet: walletApiInstance,
    bonus: bonusApiInstance,
    notification: notificationApiInstance,
}

Object.values(apiInstances).forEach(instance => {
    instance.interceptors.response.use(
        response => Promise.resolve(response),
        error => instance != walletApiInstance ? handlerErrorFromApis(error) : handlerErrorFromWalletApis(error)    );
});

export default apiInstances;