import { createApp} from 'vue';
import App from './App.vue';
import router from "./router";
import store from "./store";
import VueCookies from 'vue3-cookies';
// import "./assets/css/owl.carousel.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/chat.css";
import "./assets/css/chatresponsive.css";
import "./assets/css/responsive.css";
import "./assets/css/style1.css";
import "./assets/fontawesome/css/all.css";
import "./assets/css/bootstrap-icons.css"
import "bootstrap";
import 'vue3-carousel/dist/carousel.css';
import './registerServiceWorker';
import globalProperties from './globalServices';

window.addEventListener('popstate', (event) => {
    if(document.querySelector('.modal.show')) {
        window.location.reload();
    }
})
router.beforeEach((to, from, next) => {
    if (document.querySelector('.modal.show')) {
        next(false);
        return;
    }
    const underMaintenance = store.getters.underMaintenance;
    const isMaintenancePage = to.name === 'Maintenance';
    const isAuthenticated = window.store?.getters.isAuthenticated;
    const lastLoginNotSet = !window.store?.getters?.stateUser?.last_login_at;
    const isResetPasswordPage = to.name === 'reset-password';
    if (underMaintenance) {
        if (!isMaintenancePage) {
            next({ name: 'Maintenance' });
            return;
        }
    } else {
        if (isMaintenancePage) {
            next({ name: 'Home' });
        }else {
            const isLoggedIn = store.getters.isAuthenticated;
            const siteSettings = store.getters.siteSettings;
            if (siteSettings && siteSettings.business_type == 1) {
                if (to.path === '/login' || to.path === '/forget-password' || to.path === '/sign-up') {
                    if (isLoggedIn) {
                        next('/');
                    } else {
                        next();
                    }
                } else {
                    next();
                }
            } else if (siteSettings && siteSettings.business_type == 2) {
                if (to.path === '/login' || to.path === '/forget-password' || to.path === '/sign-up') {
                    if (isLoggedIn) {
                        next('/');
                    } else {
                        next();
                    }
                } else {
                    next();
                }
            } else {
                next();
            }
        }
    }
    if (isAuthenticated && lastLoginNotSet && !isResetPasswordPage) {
        next({ name: 'reset-password' });
        return;
    }
    if (document.querySelector('.modal.show')) {
        next(false);
        return;
    }
    next();
});

const app = createApp(App);
app.use(globalProperties);
app.use(router);
app.use(store);
app.use(VueCookies);
app.mount("#app");

app.config.globalProperties.fantasy_server = "fantasycricket";
app.config.globalProperties.click_server = "fantasy";
